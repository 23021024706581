html {
  position: relative;
  min-height: 100%;
}

body {
    margin-top: 80px;
    margin-bottom: 80px;
}


// required
label.required,
legend.required {
    &:after {
        content: "*";
    }
}
label,
legend {
    color: $color-label-show;
}

// Show Entity
.show-entity,
.edit-entity {
    label,
    legend {
        width: 100%;
        font-size: 75%;
        color: $color-label-show;
        margin-bottom: 0;
        text-transform: uppercase;
    }
    .content {
        width: 100%;
        color: $color-content-show;
        background-color: $bgcolor-content-show;
        display: inline-block;
        padding: 0.2em 0.6em;
        min-height: 1.9em;
    }
    .secondary {
        .content {
            background-color: transparent;
            padding: 0;
            color: $color-content-show-secondary;
        }
    }
}


// Tables
.table {
    border-top: none;
    thead {
        border-top: none;
        tr {
            border-top: none;
            th {
                border-top: none;
            }
        }
        th {
            white-space: nowrap;
        }
        a {
            color: $color-content-show;
            text-decoration: none;
        }
        a.desc {
            &:after {
                padding-left: 4px;
                content: "▾";
            }
        }
        a.asc {
            &:after {
                padding-left: 4px;
                content: "▴";
            }
        }
    }

}

// Filter
.filter-bar {
    background-color: $bgcolor-filter-bar;
    color: $color-filter-bar;
    border-radius: 3px;
    padding: 12px;
    h3 {
        font-size: 70%;
        text-transform: uppercase;
    }
}
// Footer
.footer {
    bottom: 0px;
    position: absolute;
    height: 60px;
    width: 100%;
}
